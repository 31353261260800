<template>

    <div>
    <H1 style="margin: 20px;"> Support</H1>
    <div style="width: max-content; margin: 50px 0 30px 20px; font-size: 1.15rem;">

        <!--  <div><h1 class="text-2xl">Support</h1></div> -->

        <form ref="form" @submit.prevent="sendEmail" id="supportForm">
                          
            <label style="font-size: 1.2rem; font-weight: bold; margin: -20px 20px -5px 0">Tab Info</label>
                <div style="margin: 0 0 0 -30pxpx; font-size: 1.2rem">  
                    Please select the tab that is causing the problem, if applicable.
                <b-form-select style="width: min-content; border: 1px solid gray; font-size: 1.1rem;"
                               size="sm"
                               v-model="selectedTabtitle"
                               :options="tabTitles"></b-form-select>      
            </div>

            <label style="font-size: 1.2rem; font-weight: bold; margin: 20px 0 -5px 0">Message</label>
            <textarea 
                name="message"
                v-model="message"
                cols="30" rows="5"
                style="background-color: white">
            </textarea>

            <input type="file" name="my_file"> 

            <span style="display: hide" id="generalForm"> <label>generalForm</label>
                <input 
                    type="text" 
                    v-model="generalForm"
                    name="generalForm"
                    placeholder="generalForm">
            </span>
            

            <div style="margin: 30px 0 0 0">
            <input type="submit"><span style="font-size: 1.2rem; font-weight: bold; margin-left: 10px"> {{ response }} </span>
            </div>
        </form>

    </div>
    </div>
</template>

<style scoped>
* {
  box-sizing: border-box;
}

#generalForm {
  display: none;
}

.container {
  display: block;
  margin: auto;
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
}

label {
  float: left;
}

input[type="text"],
[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}
</style>

<script>
const axios = require('axios');
//import $ from "jquery";


export default {
  mounted() {
    console.log("Support.vue starting.");
    this.selectedTabtitle = this.tabTitles[0];
    console.log("this.selectedTabtitle=", this.selectedTabtitle);

    if (this.selectedTabtitle !== "not applicable") {
      let selectedGeneralForm = JSON.parse(
        JSON.stringify(
          this.$store.getters["moduleArray"].find(
            (x) => x.tabTitle === this.selectedTabtitle
          )
        )
      );
      delete selectedGeneralForm.browserSideOnly;
      delete selectedGeneralForm.playback;
      delete selectedGeneralForm.password;
      delete selectedGeneralForm.chartParameters.levels;

      console.log("selectedGeneralForm=", selectedGeneralForm);
      this.generalForm = JSON.stringify(selectedGeneralForm);
    }
  },
  data() {
    return {
      text: "",

      from_name: "",
      email: "",
      message: "",
      attachments: [],
      selectedFiles: [],
      fileContent: "",
      selectedTabtitle: "",
      response: "",
    };
  },
  computed: {
    tabTitles() {
      let arr = this.$store.getters["moduleArray"].map((x) => x.tabTitle);
      arr.push("not applicable");
      //console.log("arr=", arr);
      return arr;
    },
    generalForm: {
      get() {
        return JSON.stringify(
          this.$store.getters["moduleArray"].find(
            (x) => x.tabTitle === this.selectedTabtitle
          )
        );
      },
      set() {},
    },
  },
  methods: {
    sendEmail(e) {
      console.log("sendEmail() starting. e=", e);
     // console.log("this.selectedFiles=", this.selectedFiles);
     // console.log("this.attachments=", this.attachments);

      let that = this;
      var postData = JSON.stringify({
        "email": "dan@scarrtrading.com",
        "body": "wertyuiodfghjkdfghj"
    });
/*
        $.ajax(postData).done(function (response) {
  console.log(response);
});*/



        let url = "https://hooks.zapier.com/hooks/catch/875774/2expc8n/";

        const instance = axios.create({
  transformRequest: [
    function (data, headers) {
      delete headers['Content-Type'];
      return data;
    },
  ],
});


                instance.post(url, postData).then(data => {
                  console.log("data=", data);
                  let parsedData = data.data;
                        console.log("parsedData=", parsedData);
                        let temp = parsedData.map(x => x.Symbol + ",  " + x.Name).sort();
                        console.log("temp=", temp);
                        that.autoCompleteList = temp;
                        that.dataSource = temp;
                        console.log("that.dataSource=", that.dataSource);
                });

       
     
      // Reset form field
      this.from_name = "";
      this.email = "";
      this.message = "";
      this.response = "";
    },
    triggerFileInput() {
        console.log("triggerFileInput() starting.");
        this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      console.log("handleFileUpload() starting.");
      this.selectedFiles = JSON.parse(JSON.stringify(Array.from(event.target.files)));
      console.log("this.selectedFiles=", this.selectedFiles);

      let temp = this.readFile(this.selectedFiles[0]);
      console.log("temp=", temp);

     // this.attachments.push();
    },
    formatFileSize(size) {
      console.log("formatFileSize() starting.");
      const units = ["bytes", "KB", "MB", "GB", "TB"];
      let i = 0;
      while (size >= 1024 && i < units.length - 1) {
        size /= 1024;
        i++;
      }
      return `${size.toFixed(2)} ${units[i]}`;
    },
    readFile(file) {
      console.log("readFile() starting. file=", file);
      const reader = new FileReader();
      reader.onload = (e) => {
        this.fileContent = e.target.result;
        console.log("this.fileContent=", this.fileContent);
    };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsText(file);
    }
  },
};
</script>